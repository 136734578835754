import {Helmet} from "react-helmet";
import { CTAArea } from "../Landing/CTAArea";
import { Link } from "react-router-dom";
import { QuoteButton } from "../QuoteButton";
export const PrivacyPage=()=>{
    return(
        <>
        <QuoteButton/>
        <Helmet>
        <title>Digitize4Life - Privacy Policy Ensuring Your Confidentiality</title>
        <meta name="description" content="Discover how Digitize4Life prioritizes the confidentiality of your personal information. Our
privacy policy outlines the measures in place to protect your data and ensure a secure and trusted
experience. Your privacy is our commitment." />
        <meta name="keywords" content="Digitize4Life privacy, Personal information protection, Data security measures, Confidentiality
commitment, Customer privacy policy."/>
<link rel="canonical" href="https://digitize4life.com/privacy_policy" />
     </Helmet>
    
     <section className="page-banner bgs-cover text-white pt-65 pb-40" id="page-banner" style={{backgroundImage: 'url(images/privacypolicyCover.webp)'}}>
<div className="container">
  <div className="banner-inner mt-65">
    <h1 className="page-title wow fadeInUp delay-0-2s" style={{color:'#000', fontSize: 'xxx-large'}}>Privacy Policy</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb wow fadeInUp delay-0-4s">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Privacy Policy</li>
      </ol>
    </nav>
  </div>
</div>
</section>
     <section className="sm-mt-pp project-page-area rel z-1 pt-60 pb-40">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-8 col-lg-9">
        <div className="section-title text-center mb-40 wow fadeInUp delay-0-2s">
          <span className="sub-title mb-10">PRIVACY POLICY</span>
          <h2>Safeguarding Your Confidentiality</h2>
          <p>At Digitize4Life, we hold the privacy of our clients in the highest regard, and our commitment is to ensure the
complete confidentiality and protection of their personal information. Please take a moment to review the
overview of our privacy policy, outlining how we utilize and safeguard our clients' information. By submitting
their personal details, all clients explicitly and wholeheartedly agree to abide by our privacy policy</p>
        </div>
        
      </div>
      <div className="section-title wow fadeInUp delay-0-2s">
      <div className="accordion" id="faq-accordion">
        <div className="row">
          <div className="col-lg-12 mb-15 wow fadeInUp delay-0-2s">
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                Information Collection
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>Personal information is requested when clients register on our website to submit inquiries, request quotes, or
place orders. This information includes the contact name, company name, email, phone/fax, and billing
address (as needed).
</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                Purpose of Information
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>The collected personal information is utilized for processing quotes, orders, payments, and inquiries. At the
discretion of our clients, their feedback may be used for testimonials and to enhance the services offered.
With client consent, we may also use their contact information to provide promotional emails and special
offers.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                Security Measures
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>Digitize4Life employs electronic and management systems with stringent procedures to maintain the
complete security of the collected information. Our servers are fully secured and utilize encryption for all
stored data.
</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                Non-Disclosure to Third Parties
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>We strictly refrain from selling or distributing customer information to third parties unless compelled by law.
While we use third-party services for information storage and payment processing, these affiliates are not
authorized to use client information for any purpose other than providing the agreed-upon services.
.</p>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
<p>At Digitize4Life, your privacy is our top priority, and we are dedicated to upholding the trust you place in us. If
you have any questions or require further clarification regarding our privacy policy, feel free to contact us.
Thank you for choosing Digitize4Life, where your confidentiality is safeguarded with utmost care.
</p>
        </div>
     </div>
     </div>
     </section>
     <CTAArea/>
     </>
    )
}