import { Link } from "react-router-dom"
import { FaXTwitter, FaTiktok, FaYoutube } from "react-icons/fa6";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


export const Footer = () => {
  return (
    <footer className="footer-area pt-80">
      {/* footer area start */}
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div className="widget widget_about wow fadeInUp delay-0-2s">
              <div className="footer-logo mb-25">
                <Link to="/"><img loading="lazy" src="../../images/logos/logo.webp" alt="Logo Image" width="230" height="43" style={{ maxWidth: '100%', height: 'auto' }} /></Link>
              </div>
              <p></p>
              <div className="social-style-two mt-15">
                <Link aria-label="Link of Facebook page" to="https://www.facebook.com/digitize4lifellc" target="_blank"><i className="fab fa-facebook-f" /></Link>
                <Link aria-label="Link of LinkedIn account" to="https://www.linkedin.com/company/digitize4lifellc" target="_blank"><i className="fab fa-linkedin-in" /></Link>
                <Link aria-label="Link of X account" to="https://twitter.com/Digitize4lif" target="_blank"><FaXTwitter /></Link>
                <Link aria-label="Link of Alignable account" to="https://www.alignable.com/bedford-tx/digitize4life" target="_blank"><i className="fa fa-font" /></Link>
                <Link aria-label="Link of Instagram account" to="https://www.instagram.com/digitize4lifellc/" target="_blank"><i className="fab fa-instagram" /></Link>
              </div>
              <br />
              <div className="social-style-two mt-15">
                <Link aria-label="Link of Pinterest account" to="https://www.pinterest.com/digitize4lifellc" target="_blank"><i className="fab fa-pinterest-p" /></Link>
                <Link aria-label="Link of Twitch account" to="https://www.twitch.tv/digitize4life" target="_blank"><i className="fab fa-twitch" /></Link>
                <Link aria-label="Link of Tiktok account" to="https://www.tiktok.com/@digitize4lifellc" target="_blank"><FaTiktok /></Link>
                <Link aria-label="Link of Youtube account" to="https://www.youtube.com/@digitize4life_official" target="_blank"><FaYoutube /></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-md-6">
            <div className="widget widget_nav_menu wow fadeInUp delay-0-4s">
              <h4 className="widget-title">Useful Links</h4>
              <ul className="row justify-content-between">
                <li><Link to="/services/embroidery-digitizing">Embroidery Digitizing</Link></li>
                <li><Link to="https://shop.digitize4life.com/" target="_blank">Shop</Link></li>
                <li><Link to="/services/vector-conversion">Vector Conversion</Link></li>
                <li><Link to="/faq">FAQ</Link></li>
                <li><Link to="/custom-patches">Custom Patches</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/blogs">Blogs</Link></li>
                <li><Link to="/refund_policy">Refund Policy</Link></li>
                <li><Link to="https://login.digitize4life.com/">My Account</Link></li>
                <li><Link to="/privacy_policy">Privacy Policy</Link></li>
                <li><Link to="https://login.digitize4life.com/register.asp">Get Started</Link></li>
                <li><Link to="/termsofuse">Terms & Conditions</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="widget widget_contact_info wow fadeInUp delay-0-6s">
              <h4 className="widget-title">Support</h4>
              <ul>
                {/*<li><i className="far fa-map-marker-alt" /> 55 Main Srteet, New York</li> */}
                <li><i className="far fa-envelope" /> <Link aria-label="Email Us" to="https://webtend.site/cdn-cgi/l/email-protection#bac9cfcacad5c8cefaddd7dbd3d694d9d5d7"><span className="__cf_email__" data-cfemail="bccfc9ccccd3cec8fcdbd1ddd5d092dfd3d1">sales@digitize4life.com</span></Link></li>
                <li><i className="far fa-phone" /> <Link aria-label="Call Us" href="tel:800-620-6979">800-620-6979</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom mt-15 pt-25 pb-10">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="copyright-text text-center text-lg-start">
                <p className="text-white mb-0">Copyright © 2012 - 2024 Digitize4life, LLC. All Rights Reserved.</p>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="payment-method-image mb-15 text-center text-lg-end">
                <a href="#"><img loading="lazy" src="../../images/footer/payment-method.webp" alt="Payment Method" width="352" height="31" style={{ maxWidth: '100%', height: 'auto' }} /></a>
              </div>
            </div>
          </div>
          {/* back to top area start */}
          <div className="back-to-top">
            <span className="back-top"><i className="fa fa-angle-up" /></span>
          </div>
          {/* back to top area end */}
        </div>
      </div>
      {/* footer area end */}
      <a href="https://wa.me/18178571034" aria-label="WhatsApp" className="whatsapp-float" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </a>
    </footer>

  )
}