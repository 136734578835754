import React, { useState, useEffect } from 'react';
import { Circle } from 'rc-progress'; // Assuming you have a progress circle component

export const CircleProgress = ({ value, maxValue }) => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgressValue(value * 110); // Convert value to percentage
    }, 500); // Adjust delay as needed

    return () => clearTimeout(timer);
  }, [value]);

  // Format the progress value
  const formattedProgress = value > 50000 ? '50K+' : maxValue/1000 + 'K+';

  return (
    <div className='circlewrapper'>
      <Circle percent={progressValue} strokeWidth={7} trailWidth={7} strokeLinecap="square" strokeColor={['#ff7e5f', '#feb47b']} />
      <h3 className='circleper'>{formattedProgress}</h3>
    </div>
  );
};
