import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import { QuoteButton } from "../QuoteButton";
export const TermsPage=()=>{
    return(
        <>
        <QuoteButton/>
        <Helmet>
        <title>Digitize4life - Terms of Use for Seamless Business</title>
        <meta name="description" content="Explore the fair business practices and privacy policies at Digitize4life. Safeguarding your
privacy is our priority. Understand our terms and conditions, and ensure a smooth collaboration. Your
satisfaction is our commitment." />
        <meta name="keywords" content="Digitize4life terms, Privacy policy, Quality control measures, Refund policy, Customer satisfaction,
Fair business practices."/>
<link rel="canonical" href="https://digitize4life.com/termsofuse" />
     </Helmet>
     <section className="page-banner bgs-cover text-white pt-65 pb-40" id="page-banner" style={{backgroundImage: 'url(images/termscover.webp)'}}>
<div className="container">
  <div className="banner-inner mt-65">
    <h1 className="termsH page-title wow fadeInUp delay-0-2s" style={{ fontSize: 'xxx-large'}}>Terms & Conditions</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb wow fadeInUp delay-0-4s">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Terms & Conditions</li>
      </ol>
    </nav>
  </div>
</div>
</section>
     <section className="sm-mt project-page-area rel z-1 pt-60 pb-40">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-8 col-lg-9">
        <div className="section-title text-center mb-40 wow fadeInUp delay-0-2s">
          <span className="sub-title mb-10">TERMS OF USE</span>
          <h2>Navigating Fair Business with Digitize4life</h2>
          <p>Welcome to Digitize4life, where we prioritize fair business practices and value your privacy. Please read our
terms of use carefully to understand the guidelines for engaging with our services. Your satisfaction is at the
heart of everything we do.</p>
        </div>
        
      </div>
      <div className="section-title wow fadeInUp delay-0-2s">
      <div className="accordion" id="faq-accordion">
        <div className="row">
          <div className="col-lg-12 mb-15 wow fadeInUp delay-0-2s">
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                Privacy Assurance
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>At Digitize4life, we are committed to safeguarding the privacy of our customers to the fullest extent. All
content on this website is the exclusive property of Digitize4life and may not be used or reproduced without
written consent from our management.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                Quality Control Measures
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>We implement strict quality control measures to ensure the highest standards in our services. Every original
order placed with us includes a scanned real sew-out, providing you with a tangible preview of the final
product.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                File Delivery and Formats
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>Orders placed with Digitize4life will be promptly emailed to customers in the requested file formats. We
prioritize efficiency in delivering your orders, ensuring a seamless and convenient experience.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                Refund Policy
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>Our refund policy is transparent and is separately outlined on our website. We encourage you to review it to
understand the procedures and conditions for refunds.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive">
                Sample Run for Satisfaction
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>We urge all Digitize4life customers to run a digital sample before moving to full production. This step ensures that
you are completely satisfied with the quality and specifications of your order.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item style-two">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix">
                Your Satisfaction, Our Commitment
                </button>
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                <div className="accordion-body">
                  <p>At Digitize4life, our aim is to provide you with a positive and fulfilling experience. By adhering to these terms
of use, we seek to maintain transparency, protect your privacy, and ensure that every interaction with us is
built on fairness and trust.</p>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
<p>Should you have any questions or require further clarification on our terms of use, feel free to reach out to us.
Thank you for choosing Digitize4life, where your satisfaction is our commitment to excellence.</p>
        </div>
     </div>
     </div>
     </section>
     </>
    )
}