import { useState } from "react"
import { CTAArea } from "../Landing/CTAArea"
import axios from 'axios'
import { helmetJsonLdProp } from "react-schemaorg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { QuoteButton } from "../QuoteButton";

export const ContactPage=()=>{
    const [name,setname]=useState("")
    const [email,setemail]=useState("")
    const [phone,setphone]=useState("")
    const [subject, setsubject] = useState(localStorage.getItem('services') || 'select a service');
    const [message,setmessage]=useState("")
    console.log('subject',subject);

    const ContactMe=async(e)=>{
      e.preventDefault();
      try{
        const res=await axios.post('https://shop.digitize4life.com/api/v2/contact/post',{name,email,phone,subject,message},{
          headers:{
            Accept: 'application/json'
          }
        })
        console.log('name',name,'email',email,'phone',phone,'subject',subject,'message',message,'res',res.data)
        if(res.data.status==='success'){
          alert(res.data.message)
          setphone("")
          setemail("")
          setsubject('Select a service')
          setname("")
          setmessage("")
          localStorage.removeItem('services')
        }
      }catch(err){
        console.log(err);
      }
    }


    return(
        <>
        <QuoteButton/>
        <Helmet>
        <title>Contact Digitize4life - Embroidery Digitizing Excellence</title>
        <link rel="canonical" href="https://digitize4life.com/contact" />
        <script type="application/ld+json">
        {JSON.stringify(
      helmetJsonLdProp({
        
  "@context": "http://schema.org",
  "@type": "LocalBusiness",
  "name": "Digitizing 4 Life",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "1920 Oak Timbers Dr, Bedford",
    "addressLocality": "Bedford",
    "addressRegion": "State",
    "postalCode": "TX 76021",
    "addressCountry": "United States"
  },
  "telephone": "800-620-6979",
  "openingHours": "Mo-Fr 01:00-24:00",
  "priceRange": "$$"
      })
    )}
    </script>
        </Helmet>
        {/*Page Banner Start */}
        
        <section className="page-banner bgs-cover text-white pt-65 pb-40" id="page-banner" style={{backgroundImage: 'url(images/Contactpagecover.webp)'}}>
<div className="container">
  <div className="banner-inner mt-65">
    <h1 className="page-title wow fadeInUp delay-0-2s" style={{color:'#000', fontSize: 'xxx-large'}}>Contact Us</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb wow fadeInUp delay-0-4s">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Contact Us</li>
      </ol>
    </nav>
  </div>
</div>
</section>
         {/*Page Banner end */}
        <div>
  {/* Contact Info Area start */}
  <section className="contact-info-area pt-60 pb-40">
    <div className="container">
      <div className="row no-gap">
        <div className="col-lg-6">
          <div className="contact-info-content wow fadeInLeft delay-0-2s">
            <div className="section-title mb-25">
              <span className="sub-title mb-15">Contact Us</span>
              <h2>We’re Ready to Help! Feel Free to Contact With Us</h2>
            </div>
            <p>Call us (Monday to Friday 9am to 6pm CST) Or email
us 24/7 for prompt reply mention urgency..</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="contact-info-wrap wow fadeInRight delay-0-2s">
            <div className="contact-info-item">
              <div className="icon"><i className="fal fa-map-marker-alt" /></div>
              <div className="content">
                <h4>Location</h4>
                <p>1920 Oak Timbers Dr, Bedford, TX 76021</p>
              </div>
            </div>
            <div className="contact-info-item">
              <div className="icon"><i className="far fa-envelope-open" /></div>
              <div className="content">
                <h4>Email Us</h4>
                <a href="https://webtend.site/cdn-cgi/l/sales@digitize4life.com"><span className="__cf_email__" >sales@digitize4life.com</span></a>
              </div>
            </div>
            <div className="contact-info-item">
              <div className="icon"><i className="far fa-phone" /></div>
              <div className="content">
                <h4>Phone No</h4>
                <a href="tel:817-857-1034">+1 (817) 857-1034</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Contact Info Area end */}
  {/* Location Map Area Start */}
  <div className="contact-page-map wow fadeInUp delay-0-2s">
    <div className="our-location">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3352.1207655174726!2d-97.10197889999999!3d32.84205399999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7fd04159a4af%3A0x8520120a61d8b00d!2s1920%20Oak%20Timber%20Dr%2C%20Bedford%2C%20TX%2076021%2C%20USA!5e0!3m2!1sen!2s!4v1704259508375!5m2!1sen!2s" style={{border: 0, width: '100%'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
    </div>
  </div>
  {/* Location Map Area End */}
  {/* Contact Form Start */}
  <section className="contact-form-area mt-40 mb-40 wow fadeInUp delay-0-2s">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <form id="contactForm"  className="contact-form z-1 rel" name="contactForm" onSubmit={ContactMe}>
            <div className="section-title text-center mb-40">
              <span className="sub-title">Get In Touch</span>
              <h2>Send Us Message</h2>
            </div>
            <div className="row mt-25">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="name"><i className="far fa-user mx-3" /></label>
                  <input type="text" id="name" name="name" className="form-control" placeholder="Full Name" value={name} onChange={(e)=>setname(e.target.value)} required data-error="Please enter your name" />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="email"><i className="far fa-envelope mx-3" /></label>
                  <input type="email" id="email" name="email" className="form-control" placeholder="Email Address" value={email} onChange={(e)=>setemail(e.target.value)} required data-error="Please enter your name" />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="phone"><i className="far fa-phone mx-3" /></label>
                  <input type="text" id="phone" name="phone" className="form-control" value={phone} onChange={(e)=>setphone(e.target.value)}  placeholder="Phone Number" />
                </div>
              </div>
              <div className="col-sm-6 mb-30">
                <div className="form-group">
                  <label htmlFor="subject"><i className="far fa-question-circle mx-3" /></label>
                  <select id="subject" value={subject} onChange={(e)=>setsubject(e.target.value)}>
                    <option value="default" selected>Select a service</option>
                    <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                    <option value="Vector Conversion">Vector Conversion</option>
                    <option value="Custom Patches">Custom Patches</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="message"><i className="far fa-highlighter mx-3" /></label>
                  <textarea name="message" id="message" className="form-control" value={message} onChange={(e)=>setmessage(e.target.value)} rows={4} placeholder="Write your Message" required data-error="Please enter your Message" />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="tac-wrap mb-30">
                  <input type="checkbox" id="tac" name="tac" defaultValue="tac" />
                  <label htmlFor="tac">I agree that my data is collected and stored.</label>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group mb-0">
                  <button type="submit" className="theme-btn">Send Message <i className="fas fa-arrow-right" /></button>
                  <div id="msgSubmit" className="hidden" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  {/* Contact Form End */}
  {/* CTA Area start */}
    <CTAArea/>
  {/* CTA Area end */}
</div>

        </>
    )
}