import { NavLink } from "react-router-dom"
import { FaTiktok, FaXTwitter } from "react-icons/fa6"
import { useState } from "react"

export const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Function to toggle mobile menu state
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      {/*<!-- preload end --> */}
      {/*<!-- search popup start --> */}
      {/* <Searchpopup/> */}
      {/*<!-- search popup end --> */}
      {/*<!-- sidebar start --> */}
      {/* <Sidebar/> */}
      {/*<!-- sidebar end --> */}
      {/*<!-- navbar start --> */}

      <div>
        <div className="navbar-top style-one text-white bgs-cover" style={{ backgroundImage: 'url(images/background/header-top-bg.webp)' }}>
          <div className="container container-1570">
            <div className="row">
              <div className="col-lg-6">
                <div className="topbar-left text-lg-start text-center">
                  <span className="off">20% OFF</span>
                  <span>Free Shipping on all U.S. Orders $100+</span>
                </div>
              </div>
              <div className="col-lg-6">
                <ul className="topbar-right justify-content-center justify-content-lg-end">
                  <li><i className="fal fa-phone" /> <b>Call :<NavLink aria-label="Call Button" to="tel:817-857-1034">817-857-1034</NavLink></b></li>
                  <li className="social-style-one">
                    <NavLink to="https://www.facebook.com/digitize4lifellc/" target="_blank" aria-label="Facebook Page"><i className="fab fa-facebook-f" aria-hidden="true" /></NavLink>
                    <NavLink to="https://twitter.com/Digitize4lif" target="_blank" aria-label="X Account"><FaXTwitter /></NavLink>
                    <NavLink to="https://www.alignable.com/bedford-tx/digitize4life/" target="_blank" aria-label="Alignable Account"><i className="fa fa-font" aria-hidden="true" /></NavLink>
                    <NavLink to="https://www.instagram.com/digitize4lifellc/" target="_blank" aria-label="Instagram Account"><i className="fab fa-instagram" aria-hidden="true" /></NavLink>
                    <NavLink to="https://www.linkedin.com/company/digitize4lifellc" target="_blank" aria-label="LinkedIn Account"><i className="fab fa-linkedin-in" /></NavLink>
                    <NavLink to="https://www.tiktok.com/@digitize4lifellc" target="_blank" className="mb-1" aria-label="TikTok Account"><FaTiktok /></NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar style-one navbar-area navbar-expand-lg py-20">
          <div className="container container-1570">
            <div className="responsive-mobile-menu">
              <button className={`menu toggle-btn d-block d-lg-none ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu} data-target="#Iitechie_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo">
              <NavLink to="/"><img src="../../images/logos/logo.webp" alt="Logo" width="170" height="32" style={{ maxWidth: '100%', height: 'auto' }} /></NavLink>
            </div>
            <div className="nav-right-part nav-right-part-mobile">

            </div>
            <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`} id="Iitechie_main_menu">
              <ul className="navbar-nav menu-open text-lg-end">
                <li><NavLink aria-label="Go to Home page" to="/" onClick={toggleMobileMenu}>Home</NavLink></li>
                <li className="menu-item-has-children"><NavLink to="/services"><span onClick={toggleMobileMenu}>Services</span></NavLink>
                  <ul className="sub-menu">
                    <li className="menu-item-has-children"><NavLink aria-label="Embroidery Digitizing page link" to="/services/embroidery-digitizing"><span onClick={toggleMobileMenu}>Embroidery Digitizing</span></NavLink>
                      <ul className="sub-menu">
                        <li><NavLink to="/services/flat-embroidery-digitizing" onClick={toggleMobileMenu}>Flat Embroidery Digitizing</NavLink></li>
                        <li><NavLink to="/services/applique-embroidery-digitizing" onClick={toggleMobileMenu}>Applique Embroidery Digitizing</NavLink></li>
                        <li><NavLink to="/services/3D-puff-embroidery-digitizing" onClick={toggleMobileMenu}>3D Puff Embroidery Digitizing</NavLink></li>
                        <li><NavLink to="/services/chenille-embroidery-digitizing" onClick={toggleMobileMenu}>Chenille Embroidery Digitizing</NavLink></li>
                        <li><NavLink to="/services/sequin-embroidery-digitizing" onClick={toggleMobileMenu}>Sequin Embroidery Digitizing</NavLink></li>
                      </ul></li>
                    <li><NavLink to="/services/vector-conversion" onClick={toggleMobileMenu}>Vector Conversion</NavLink></li>
                    <li className="menu-item-has-children"><NavLink aria-label="Graphic Designing page link" to="/services/graphic-designing"><span onClick={toggleMobileMenu}>Graphic Designing</span></NavLink>
                      <ul className="sub-menu">
                        <li><NavLink to="/services/logo-designing" onClick={toggleMobileMenu}>Logo Designing</NavLink></li>
                        <li><NavLink to="/services/photo-editing" onClick={toggleMobileMenu}>Photo Editing</NavLink></li>
                      </ul></li>
                    <li><NavLink to="/services/line-art" onClick={toggleMobileMenu}>Line Art</NavLink></li>
                  </ul>
                </li>
                <li className="menu-item-has-children"><NavLink to="/custom-patches"><span onClick={toggleMobileMenu}>Custom Patches</span></NavLink>
                  <ul className="sub-menu">
                    <li><NavLink to="/custom-patches/embroidered-patches" onClick={toggleMobileMenu}>Embroidered Patches</NavLink></li>
                    <li><NavLink to="/custom-patches/chenille-patches" onClick={toggleMobileMenu}>Chenille Patches</NavLink></li>
                    <li><NavLink to="/custom-patches/woven-patches" onClick={toggleMobileMenu}>Woven Patches</NavLink></li>
                    <li><NavLink to="/custom-patches/pvc-patches" onClick={toggleMobileMenu}>PVC Patches</NavLink></li>
                    <li><NavLink to="/custom-patches/printed-patches" onClick={toggleMobileMenu}>Printed Patches</NavLink></li>
                    <li><NavLink to="/custom-patches/leather-patches" onClick={toggleMobileMenu}>Leather Patches</NavLink></li>
                  </ul></li>
                <li><NavLink to="/portfolio" onClick={toggleMobileMenu}>Portfolio</NavLink></li>
                <li><NavLink to="/pricing" onClick={toggleMobileMenu}>Pricing</NavLink></li>
                <li><NavLink to="/contact" onClick={toggleMobileMenu}>Contact</NavLink></li>
                <li><NavLink to="https://shop.digitize4life.com/" target="_blank">Shop</NavLink></li>
              </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop">
              <NavLink aria-label="Go to Login Page" to="https://login.digitize4life.com/" className="theme-btn style-two">Login <i className="far fa-long-arrow-right" /></NavLink>
              <NavLink aria-label="Go to Registration Page" to="https://login.digitize4life.com/register.asp" className="theme-btn style-two">Registration <i className="far fa-long-arrow-right" /></NavLink>
              {/* <div className="menu-sidebar">
          <button aria-label="Button Appointment Form">
            <i className="far fa-ellipsis-h" />
            <i className="far fa-ellipsis-h" />
            <i className="far fa-ellipsis-h" />
          </button>
        </div> */}
            </div>
          </div>
        </nav>
      </div>

      {/*<!-- navbar end --> */}
    </>
  )
}