// Project.jsx
import React, { Suspense, lazy } from "react";
import { Link } from "react-router-dom";

// Lazy load ProjectItem component
const ProjectItem = lazy(() => import('./ProjectItem'));

const projects = [
  {
    id: "project-tab-one",
    imgSrc: "images/Home/helmet-embroidered-patch-446x448.webp",
    imgAlt: "Image of an Embroidered Patch",
    title: "Embroidered Patch",
    link: "/custom-patches/embroidered-patches",
    description: "Embroidered Patch",
    tabImage: "images/Home/helmet-embroidered-patch-50x50.webp",
  },
  {
    id: "project-tab-two",
    imgSrc: "images/Home/jets-chenille-patch.webp",
    imgAlt: "Image of a Chenille Patch",
    title: "Chenille Patch",
    link: "/custom-patches/chenille-patches",
    description: "Chenille Patch",
    tabImage: "images/Home/jets-chenille-patch-50x50.webp",
  },
  {
    id: "project-tab-three",
    imgSrc: "images/Home/Dekni-Leather-Patch.webp",
    imgAlt: "Image of a Leather Patch",
    title: "Leather Patch",
    link: "/custom-patches/leather-patches",
    description: "Leather Patch",
    tabImage: "images/Home/Dekni-Leather-Patch-50x50.webp",
  },
  {
    id: "project-tab-four",
    imgSrc: "images/Home/wisdom-sequin-patch.webp",
    imgAlt: "Image of Sequin Embroidery",
    title: "Sequin Embroidery",
    link: "/services/sequin-embroidery-digitizing",
    description: "Sequin Embroidery",
    tabImage: "images/Home/wisdom-sequin-patch-50x50.webp",
  },
];

export const Project = () => {
  return (
    <Suspense fallback={<div>Loading projects...</div>}>
      <section className="latest-project-area pt-40 pb-40">
        <div className="container">
          <div className="row justify-content-between align-items-center pb-45">
            <div className="col-lg-6">
              <div className="section-title mb-15 wow fadeInLeft delay-0-2s">
                <span className="sub-title mb-10">Recent Deliveries</span>
                <h2>Have a look at our recent Amazing Patches!</h2>
              </div>
            </div>
            <div className="col-lg-4 text-lg-end">
              <Link aria-label="Go to Portfolio page" to="/portfolio" className="theme-btn mt-15 wow fadeInRight delay-0-2s">
                View More Projects <i className="far fa-long-arrow-right" />
              </Link>
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-4">
              <div className="tab-btns-one rmb-75 nav flex-column nav-pills wow fadeInRight delay-0-2s">
                {projects.map((project, index) => (
                  <button
                    key={project.id}
                    className={`nav-link ${index === 0 ? "active" : ""}`}
                    data-bs-toggle="pill"
                    data-bs-target={`#${project.id}`}
                  >
                    <img loading="lazy" src={project.tabImage} alt={project.imgAlt} width={50} height={50} />
                    <span className="title mx-2">{project.title}</span>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="project-tab-content rel wow fadeInLeft delay-0-2s">
                <div className="tab-content">
                  {projects.map((project, index) => (
                    <div key={project.id} className={`tab-pane fade ${index === 0 ? "show active" : ""}`} id={project.id}>
                      <ProjectItem project={project} />
                    </div>
                  ))}
                </div>
                <div className="circle-shapes">
                  <div className="shape-inner">
                    <span className="dot-one" />
                    <span className="dot-two" />
                    <span className="dot-three" />
                    <span className="dot-four" />
                    <span className="dot-five" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
};
