import { Routes, Route } from 'react-router-dom'
import { Home } from './Component/Pages/Home';
import { ErrorPage } from './Component/Pages/ErrorPage';
import { ContactPage } from './Component/Pages/ContactPage';
import { AboutPage } from './Component/Pages/AboutPage';
import { Blog } from './Component/Pages/Blog';
import { Blogdetail } from './Component/Pages/Blogdetail';
import { FAQ } from './Component/Pages/FAQ';
import { PricingPage } from './Component/Pages/PricingPage';
import { ServicesPage } from './Component/Pages/ServicesPage';
import { Servicedetail } from './Component/Pages/Servicedetail';
import { PortfolioPage } from './Component/Pages/PortfolioPage';
import { ShopPage } from './Component/Pages/ShopPage';
import { TermsPage } from './Component/Pages/TermsPage';
import { RefundPage } from './Component/Pages/RefundPage';
import { PrivacyPage } from './Component/Pages/PrivacyPage';
import ScrollToTop from './Component/ScrollToTop';
import { PatcheDetailPage } from './Component/Pages/PatcheDetailPage';
import './css/flaticon.min.css';
import "./css/slick.min.css";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:category" element={<Blog />} />
        <Route path="/blog/:blogdetail" element={<Blogdetail />} />
        <Route path="/services/:name" element={<Servicedetail />} />
        <Route path="/custom-patches" element={<ShopPage />} />
        <Route path="/custom-patches/:name" element={<PatcheDetailPage />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/termsofuse" element={<TermsPage />} />
        <Route path="/refund_policy" element={<RefundPage />} />
        <Route path="/privacy_policy" element={<PrivacyPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;

