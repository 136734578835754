import { Link } from "react-router-dom"


export const Banner=({title,metatitle,metades,image})=>{
    console.log('image',image);
    return(
  <section className="page-banner bgs-cover text-white pt-65 pb-40" id="page-banner" style={{backgroundImage: `url(${image})`}} alt={metades} title={metatitle}>

  <div className="container">
    <div className="banner-inner mt-65">
      <h1 className="page-title wow fadeInUp delay-0-2s" style={{color:'#000', fontSize: 'xxx-large'}}>{title}</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb wow fadeInUp delay-0-4s">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active">{title}</li>
        </ol>
      </nav>
    </div>
  </div>
</section>
    )
}