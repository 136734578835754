import React, { Suspense, lazy } from "react";
import { Link } from "react-router-dom";

const LazyServiceItem = lazy(() => import('./ServiceItem'));

export const Services = () => {
  const servicesData = [
    {
      title: "Embroidery Digitizing",
      link: "/services/embroidery-digitizing",
      icon: "flaticon-print",
      description: "Unveiling the art of precision. Our embroidery digitizing services bring your designs to life with flat embroidery, graphic designing, applique digitizing, line art, 3D puff embroidery, logo designing, chenille digitizing, color separation, sequin digitizing, and photo editing.",
    },
    {
      title: "Custom Patches",
      link: "/custom-patches",
      icon: "flaticon-3d-printer",
      description: "Crafting patches that tell your story. Our custom patches service offers a variety of options, including embroidered patches, chenille patches, woven patches, PVC patches, printed patches, and leather patches.",
    },
    {
      title: "3D Puff Embroidery",
      link: "/services/3D-puff-embroidery-digitizing",
      icon: "flaticon-print-1",
      description: "Step into the future with our 3D puff embroidery service. Immerse yourself in innovative designs and unparalleled quality. Unleash creativity with precision and flair.",
    },
    {
      title: "Vector Conversion",
      link: "/services/vector-conversion",
      icon: "flaticon-focus",
      description: "Transforming concepts into vectors. Explore our vector conversion service, where ideas take shape with crisp lines and flawless details. Redefine your graphics with precision and clarity.",
    },
  ];

  return (
    <div className="services-area rel z-1">
      <div className="container-fluid">
        <div className="services-inner text-white bgc-black">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-7">
              <div className="service-content rel p-100 py-40 rpb-100 wow fadeInUp delay-0-2s">
                <div className="section-title mb-30">
                  <span className="sub-title mb-15">Top Embroidery Digitizing Company</span>
                  <h2>Pixel Perfect Digitizing For Embroidery and Custom Patches</h2>
                </div>
                <h3 className="awards">2024 Awards Winning Embroidery Digitizing Company</h3>
                <p>Transforming Designs into Masterpieces with Precision Embroidery Solutions</p>
                <Link aria-label="Explore Our Services" to="/services" className="theme-btn hover-two mt-20">Our Services <i className="far fa-long-arrow-right" /></Link>
                <div className="circle-shapes no-animation">
                  <div className="shape-inner">
                    <span className="dot-one" />
                    <span className="dot-two" />
                    <span className="dot-three" />
                    <span className="dot-four" />
                    <span className="dot-five" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row no-gap">
                <Suspense fallback={<div>Loading...</div>}>
                  {servicesData.map((service, index) => (
                    <LazyServiceItem
                      key={index}
                      icon={service.icon}
                      title={service.title}
                      description={service.description}
                      link={service.link}
                    />
                  ))}
                </Suspense>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Link aria-label="Go to Registration Page" to="https://login.digitize4life.com/register.asp" className="theme-btn hover-two mt-20 mb-3">Get started<i className="far fa-long-arrow-right" /></Link>
          </div>
        </div>
      </div>
    </div>
  );
};
