import { Link } from "react-router-dom"

export const QuoteButton = () => {
    return (
       
            <div class="sidebarmenuright-container">
                <a href="/contact#contactForm">
                    <button>
                        Free Quote
                    </button>
                </a>
            </div>
        
    )
}