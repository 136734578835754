import { Link } from "react-router-dom"
import { CTAArea } from "../Landing/CTAArea"

export const ErrorPage=()=>{
    return(
   <>
      {/* Page Banner Start */}
<section className="page-banner style-two text-center bgc-lighter pt-70 pb-75">
  <div className="container">
    <div className="banner-inner pt-20">
      <h1 className="page-title wow fadeInUp delay-0-2s">404</h1>
    </div>
  </div>
</section>
{/* Page Banner End */}
<div>
  {/* Error Page Start */}
  <section className="error-page-area py-60">
    <div className="container">
      <div className="error-page-content text-center">
        <div className="image mb-65 wow fadeInUp delay-0-2s">
          <img src="assets/images/404.png" alt="404 Erro" />
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="section-title wow fadeInUp delay-0-2s">
              <h2><span>Opps!</span> This Page are Can’t Be Found</h2>
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam aperiam eaquey ipsa quae abillo inventore veritatis et quasie</p>
            </div>
          </div>
        </div>
        <Link to="/" className="theme-btn mt-15 wow fadeInUp delay-0-2s">Go To Home <i className="far fa-long-arrow-right" /></Link>
      </div>
    </div>
  </section>
  {/* Error Page End */}
  {/* CTA Area start */}
  <CTAArea/>
  {/* CTA Area end */}
  {/* footer top start */}
  {/* footer top end */}
</div>

   </>
    )
}