import React, { Suspense, lazy } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay'; // Import Swiper CSS for autoplay
import { Autoplay } from 'swiper/modules'; // Correctly import Autoplay module

const TestimonialItem = lazy(() => import('./TestimonialItem')); // Lazy load TestimonialItem

// Testimonials data
const testimonialsData = [
  {
    text: "They are awesome in custom patches and embroidery digitizing services … I highly recommend digitize4life...",
    author: "Mark Diaz",
    image: "images/TrustPilot.webp",
  },
  {
    text: "Positive: Quality",
    author: "Zamani Sicard",
    image: "images/Google.webp",
  },
  {
    text: "I am so happy with the digitizing",
    author: "Chris",
    image: "images/GURU.webp",
  },
  // Add the rest of your testimonials here...
  {
    text: "They are pro professionals and did quality work! They were very helpful as I was new to this and I have got great feedback on my orders from my customers which was done and delivered on time because of digitize4life",
    author: "Reginald Scantlebury",
    image: "images/Google.webp",
  },
  {
    text: "A first-class brochure! Thanks so much for your patience, and your beautiful work on the brochure!",
    author: "Wendy Beltinck",
    image: "images/GURU.webp",
  },
];

export const Testimonials = () => {
  const swiperSettings = {
    modules: [Autoplay], // Register the Autoplay module properly
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 800,
    autoplay: {
      delay: 4000, // 4 seconds between slides
      disableOnInteraction: false, // Autoplay even after user interaction
    },
    lazy: true, // Enable lazy loading (if you want to keep it)
    loop: true, // Infinite loop
  };

  return (
    <section className="testimonials-area pt-40">
      <div className="container rel">
        <div className="row justify-content-between pb-35">
          <div className="col-lg-5">
            <div className="section-title mb-20 wow fadeInRight delay-0-2s">
              <span className="sub-title mb-10">Testimonials</span>
              <h3 className="our-clients">
                See the Stories of Delight from Our Valued Clients
              </h3>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="global-clients mb-20 wow fadeInLeft delay-0-2s">
              <h4>Global Clients</h4>
              <div className="images">
                <img loading="lazy"
                  src="images/testimonials/global-client2.jpg" // Use src instead of data-src
                  alt="Global client"
                />
                <img loading="lazy"
                  src="images/testimonials/global-client3.jpg"
                  alt="Global client"
                />
                <img loading="lazy"
                  src="images/testimonials/global-client4.jpg"
                  alt="Global client"
                />
                <img loading="lazy"
                  src="images/testimonials/global-client5.jpg"
                  alt="Global client"
                />
                <img loading="lazy"
                  src="images/testimonials/global-client6.jpg"
                  alt="Global client"
                />
                <img loading="lazy"
                  src="images/testimonials/global-client7.jpg"
                  alt="Global client"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="testimonial-item">
              <div className="image">
                <img loading="lazy"
                   src="images/Home/smiling-couple.webp" // Use src instead of data-src
                  alt="Smiling Couple"
                  width="370"
                  height="430"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <Swiper {...swiperSettings} className="testimonial-slider">
              <Suspense fallback={<div>Loading Testimonials...</div>}>
                {testimonialsData.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <TestimonialItem
                      text={testimonial.text}
                      author={testimonial.author}
                      image={testimonial.image}
                    />
                  </SwiperSlide>
                ))}
              </Suspense>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};
