import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

const counterData = [
  {
    id: "activeUsers",
    icon: "far fa-plus",
    title: "Active User on Products We Created",
    description: "Engaging and serving a vibrant community with our digitized creations.",
    target: 40,
  },
  {
    id: "printingProjects",
    icon: "far fa-plus",
    title: "A Multitude of Printing Projects",
    description: "Embarking on numerous printing ventures, bringing ideas to life.",
    target: 55,
  },
  {
    id: "completedProjects",
    icon: "far fa-plus",
    title: "Projects Successfully Completed",
    description: "Demonstrating our dedication and efficiency in delivering quality outcomes.",
    target: 100,
  },
  {
    id: "establishedYear",
    icon: "far fa-plus",
    title: "Become established in",
    description: "A legacy of expertise, evolving with each passing year.",
    target: 2012,
  },
];

export const Counter = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [counts, setCounts] = useState({
    activeUsers: 0,
    printingProjects: 0,
    completedProjects: 0,
    establishedYear: 0,
  });

  const factCounterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (factCounterRef.current) {
      observer.observe(factCounterRef.current);
    }

    return () => {
      if (factCounterRef.current) {
        observer.unobserve(factCounterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setCounts((prevCounts) => {
          const newCounts = { ...prevCounts };

          for (const key in newCounts) {
            if (key === "establishedYear" && newCounts[key] < counterData.find(item => item.id === key).target) {
              newCounts[key] += 200;
              if (newCounts[key] > counterData.find(item => item.id === key).target) {
                newCounts[key] = counterData.find(item => item.id === key).target;
              }
            } else if (newCounts[key] < counterData.find(item => item.id === key).target) {
              newCounts[key]++;
            }
          }

          return newCounts;
        });
      }, 60);

      return () => clearInterval(interval);
    }
  }, [isVisible]);

  return (
    <section className="fact-counter-area pt-40" ref={factCounterRef}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(
            helmetJsonLdProp({
              "@context": "http://schema.org",
              "@type": "AggregateRating",
              ratingValue: "5",
              reviewCount: "218",
            })
          )}
        </script>
      </Helmet>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8">
            <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
              <span className="sub-title mb-10">Company Statistics</span>
              <h2 className="fueling">Fueling Success With Proven Numbers</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {counterData.map((item, index) => (
            <div key={item.id} className="col-xl-3 col-md-6">
              <div className={`counter-item wow fadeInUp delay-0-${(index + 2) * 2}s`}>
                <div className="icon">
                  <i className={item.icon} />
                </div>
                <p>{item.title}</p>
                <span className={`count-text ${item.id === 'establishedYear' ? '' : 'k-plus'}`} data-speed={3000} data-stop={item.target}>
                  {counts[item.id]}
                </span>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center">
          Join us in the realm of accomplishment, where every statistic represents a story of triumph and satisfaction.
        </div>
      </div>
    </section>
  );
};
